body {
  margin: 40px auto;
  max-width: 650px;
  line-height: 1.6;
  font-size: 18px;
  color: #222;
  padding: 0 10px;
}
h1,
h2,
h3 {
  line-height: 1.2;
}
a:visited {
  color: blue;
}

.horiz-list {
  list-style-type: none;
  justify-content: center;
  padding: 0;
  display: flex;
}

.horiz-list li {
  margin-right: 15px;
}

.horiz-list li:last-child {
  margin-right: 0;
}